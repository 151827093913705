import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SHOW_SNACKBAR_FORMATTED,
} from "redux/actions";
import initialState from "redux/reducers/initialState";
import { APP_CONFIG_MODE } from "appConfig";

export default function snackbarReducer(state = initialState, action) {
  if (APP_CONFIG_MODE === "dev") {
    console.log("Store will update with action", action);
  }
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        show: true,
      };
    case SHOW_SNACKBAR_FORMATTED:
      return {
        ...state,
        message: action.message,
        args: action.args,
        messageType: action.messageType,
        showFormatted: true,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        show: false,
        showFormatted: false,
      };
    default:
      return {
        ...state,
      };
  }
}
