export const TYPES = {
  INTEGER: 0,
  STRING: 1,
  FLOAT: 2,
  DOUBLE: 3,
  DATE: 4,
  DATETIME: 5,
  INTEGER_ARRAY: 10,
  STRING_ARRAY: 11,
  FLOAT_ARRAY: 12,
  DOUBLE_ARRAY: 13,
  DATE_ARRAY: 14,
  DATETIME_ARRAY: 15,
  BOOLEAN: 16,
};

export const KEYS = {
  ALL_VISITS_PAGE_SIZE: "ALL_VISITS_PAGE_SIZE",
  ALL_VISITS_COLUMNS: "ALL_VISITS_COLUMNS",
  ALL_VISITS_SORT_DIRECTION: "ALL_VISITS_SORT_DIRECTION",
  ALL_VISITS_SORT_BY_OPTION: "ALL_VISITS_SORT_BY_OPTION",
  MY_VISITS_PAGE_SIZE: "MY_VISITS_PAGE_SIZE",
  MY_VISITS_COLUMNS: "MY_VISITS_COLUMNS",
  MY_VISITS_SORT_DIRECTION: "MY_VISITS_SORT_DIRECTION",
  MY_VISITS_SORT_BY_OPTION: "MY_VISITS_SORT_BY_OPTION",
  CLIENT_REPORTS_PAGE_SIZE: "CLIENT_VISIT_REPORTS_PAGE_SIZE",
  CLIENT_REPORTS_COLUMNS: "CLIENT_REPORTS_COLUMNS",
  USER_REPORTS_PAGE_SIZE: "USER_REPORTS_PAGE_SIZE",
  USER_REPORTS_COLUMNS: "USER_REPORTS_COLUMNS",
};

export const KEYS_AND_TYPES = {
  ALL_VISITS: {
    pageSize: {
      key: KEYS.ALL_VISITS_PAGE_SIZE,
      type: TYPES.INTEGER,
    },
    columns: {
      key: KEYS.ALL_VISITS_COLUMNS,
      type: TYPES.STRING_ARRAY,
    },
    sortDirection: {
      key: KEYS.ALL_VISITS_SORT_DIRECTION,
      type: TYPES.BOOLEAN,
    },
    sortByOption: {
      key: KEYS.ALL_VISITS_SORT_BY_OPTION,
      type: TYPES.STRING,
    },
  },
  MY_VISITS: {
    pageSize: {
      key: KEYS.MY_VISITS_PAGE_SIZE,
      type: TYPES.INTEGER,
    },
    columns: {
      key: KEYS.MY_VISITS_COLUMNS,
      type: TYPES.STRING_ARRAY,
    },
    sortDirection: {
      key: KEYS.MY_VISITS_SORT_DIRECTION,
      type: TYPES.BOOLEAN,
    },
    sortByOption: {
      key: KEYS.MY_VISITS_SORT_BY_OPTION,
      type: TYPES.STRING,
    },
  },
  CLIENT_REPORTS: {
    pageSize: {
      key: KEYS.CLIENT_REPORTS_PAGE_SIZE,
      type: TYPES.INTEGER,
    },
    columns: {
      key: KEYS.CLIENT_REPORTS_COLUMNS,
      type: TYPES.STRING_ARRAY,
    },
  },
  USER_REPORTS: {
    pageSize: {
      key: KEYS.USER_REPORTS_PAGE_SIZE,
      type: TYPES.INTEGER,
    },
    columns: {
      key: KEYS.USER_REPORTS_COLUMNS,
      type: TYPES.STRING_ARRAY,
    },
  },
};
