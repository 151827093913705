import { THEME_CODE_LIGHT } from "assets/jss/theme";

export const APP_CONFIG_REQUEST_TIMEOUT = 15000;
export const APP_CONFIG_REQUEST_TIMEOUT_ERROR_MESSAGE = `timeout of ${APP_CONFIG_REQUEST_TIMEOUT}ms exceeded`;
export const APP_CONFIG_MODE = "prod";
export const APP_CONFIG_DEV_URL =
  "https://dev.salesmanager.bosphorusiss.com/api";
//"https://localhost:44313/";
export const APP_CONFIG_PROD_URL = `${window.location.origin}/api`;
export const APP_CONFIG_DEV_STATIC_URL = `http://${window.location.hostname}:3000/`;
export const APP_CONFIG_PROD_STATIC_URL = `${window.location.origin}/`;
export const APP_CONFIG_DEFAULT_COUNTRY = "tr";
export const APP_CONFIG_DEFAULT_THEME_CODE = THEME_CODE_LIGHT;
export const APP_CONFIG_EXPORT_CSV_DELIMETER = ";";
