import initialState from "redux/reducers/initialState";
import {
  GET_VISIT_LIST,
  GET_MY_VISIT_LIST,
  GET_VISIT_CATEGORIES,
  CREATE_VISIT_CATEGORY,
  SET_MY_VISITS_MODE,
  PAGE_TRANSITION,
  UPDATE_VISIT_DETAIL,
} from "redux/actions";
import {
  ROUTE_MY_VISITS,
  ROUTE_VISITS,
} from "components/MasterPage/AppBar/LeftMenu/routesNames";
import _ from "lodash";

const initialStateCopy = _.cloneDeep(initialState.visits);

export default function visitsReducer(state = initialStateCopy, action) {
  switch (action.type) {
    case GET_VISIT_LIST:
      return {
        ...state,
        visitList: action.data,
      };
    case GET_MY_VISIT_LIST:
      return {
        ...state,
        visitList: action.data,
      };
    case GET_VISIT_CATEGORIES:
      return {
        ...state,
        visitCategories: action.data,
      };
    case CREATE_VISIT_CATEGORY:
      return {
        ...state,
        visitCategories: [...state.visitCategories, action.data],
      };
    case SET_MY_VISITS_MODE:
      return {
        ...state,
        myVisitsMode: action.data,
      };
    case PAGE_TRANSITION:
      let pathname = action.data.location.pathname;
      let myVisitsMode = state.myVisitsMode;
      if (
        (myVisitsMode && pathname === ROUTE_MY_VISITS) ||
        (!myVisitsMode && pathname === ROUTE_VISITS)
      ) {
        return state;
      } else {
        return {
          ...initialStateCopy,
          visitCategories: state.visitCategories,
        };
      }

    case UPDATE_VISIT_DETAIL:
      return {
        ...state,
        visitDetail: action.data,
      };
    default:
      return state;
  }
}
