import { combineReducers } from "redux";
import { LOGOUT_SUCCESS } from "redux/actions";
import initialState from "./initialState";
import _ from "lodash";

import sessionReducer from "redux/reducers/session";
import historyReducer from "redux/reducers/history";
import languageReducer from "redux/reducers/localization";
import themeReducer from "redux/reducers/theme";
import fullScreenReducer from "redux/reducers/fullScreen";
import snackBarReducer from "redux/reducers/snackbar";
import saveReducer from "redux/reducers/save";
import uploadReducer from "redux/reducers/upload";
import errorReducer from "redux/reducers/error";
import loadingReducer from "redux/reducers/loading";
import roleManagementReducer from "redux/reducers/roleManagement";
import userManagementReducer from "redux/reducers/userManagement";
import clientManagementReducer from "redux/reducers/clientManagement";
import visitsReducer from "redux/reducers/visits";
import visitsFilterReducer from "redux/reducers/visitsFilter";
import materialTableReducer from "redux/reducers/materialTable";
import userKeyValueStoreReducer from "redux/reducers/userKeyValueStore";
import quickFilterReducer from "redux/reducers/quickFilter";
import workersReducer from "redux/reducers/workers";

const reducers = {
  session: sessionReducer,
  history: historyReducer,
  language: languageReducer,
  themeCode: themeReducer,
  fullScreenMode: fullScreenReducer,
  snackbar: snackBarReducer,
  save: saveReducer,
  upload: uploadReducer,
  errors: errorReducer,
  loading: loadingReducer,
  roleManagement: roleManagementReducer,
  userManagement: userManagementReducer,
  clientManagement: clientManagementReducer,
  visits: visitsReducer,
  visitsFilter: visitsFilterReducer,
  materialTable: materialTableReducer,
  workers: workersReducer,
  keyValueStore: userKeyValueStoreReducer,
  quickFilter: quickFilterReducer,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    let initialStateCopy = _.cloneDeep(initialState);

    //prevent getUserInfo call after logout
    initialStateCopy.session.waiting = false;

    //prevent language change
    initialStateCopy.language = state.language;
    initialStateCopy.session.settings = state.session.settings;

    //prevent theme change
    initialStateCopy.themeCode = state.themeCode;

    return initialStateCopy;
  }
  return appReducer(state, action);
};

export default rootReducer;
