import { CHANGE_THEME } from "redux/actions/index";
import initialState from "redux/reducers/initialState";

export default function reducerTheme(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return action.data;
    default: {
      return state;
    }
  }
}
