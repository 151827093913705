import EN from "./en";
import DE from "./de";
import TR from "./tr";

const translate = {
  en: EN,
  de: DE,
  tr: TR,
};

export default translate;
