import initialState from "redux/reducers/initialState";
import {
  VISITS_RESET_FILTER,
  VISITS_APPLY_FILTER,
  VISITS_CHANGE_FILTER,
  VISITS_APPLY_FILTER_DONE,
  PAGE_TRANSITION,
} from "redux/actions";
import _ from "lodash";

const initialStateCopy = _.cloneDeep(initialState.visitsFilter);

export default function visitsReducer(state = initialStateCopy, action) {
  switch (action.type) {
    case VISITS_APPLY_FILTER: {
      return {
        ...state,
        apply: true,
      };
    }
    case VISITS_APPLY_FILTER_DONE: {
      return {
        ...state,
        apply: false,
      };
    }
    case VISITS_CHANGE_FILTER: {
      return _.cloneDeep(action.data);
    }
    case PAGE_TRANSITION:
    case VISITS_RESET_FILTER: {
      return _.cloneDeep(initialStateCopy);
    }
    default:
      return state;
  }
}
