class Translate {
  constructor(lang) {
    this.lang = lang;
  }

  get(path) {
    let pathSplitted = path.split(".");
    if (pathSplitted.length === 1) {
      let result = this.lang[pathSplitted[0]];
      return (
        (typeof result === "string" && result) || "missing localization:" + path
      );
    }
    let result = this.lang;
    try {
      pathSplitted.forEach((item) => {
        result = result[item];
      });
    } catch (e) {
      result = path;
    }
    if (!result || typeof result !== "string") {
      return path;
    }
    return result;
  }

  getFormatted() {
    const replaceMarker = "${}";
    let path = arguments[0];
    let format = this.get(path);
    let result = format;
    let index = format.indexOf(replaceMarker);
    let nextArgumentIndex = 1;
    while (index !== -1) {
      result = result.replace(replaceMarker, arguments[nextArgumentIndex]);
      nextArgumentIndex++;
      index = result.indexOf("{}");
    }
    return result;
  }
}

export default Translate;
