import {
  VISITS_TABLE_COLUMN_VISITOR,
  VISITS_TABLE_COLUMN_CONTACT_PERSON,
  VISITS_TABLE_COLUMN_START_DATE,
  VISITS_TABLE_COLUMN_CLIENT_SHORT_NAME,
  VISITS_TABLE_COLUMN_NOTES,
  VISITS_TABLE_COLUMN_TYPE_LABEL,
  VISITS_TABLE_COLUMN_STATUS,
} from "components/Common/VisitsTable/tableColumns";

export const showColumns = [
  VISITS_TABLE_COLUMN_VISITOR,
  VISITS_TABLE_COLUMN_CLIENT_SHORT_NAME,
  VISITS_TABLE_COLUMN_CONTACT_PERSON,
  VISITS_TABLE_COLUMN_START_DATE,
  VISITS_TABLE_COLUMN_NOTES,
  VISITS_TABLE_COLUMN_TYPE_LABEL,
  VISITS_TABLE_COLUMN_STATUS,
];
