export const VISITS_TABLE_COLUMN_NO = "VISIT_TABLE_COLUMN_NO";
export const VISITS_TABLE_COLUMN_VISITOR = "VISITS_TABLE_COLUMN_VISITOR";
export const VISITS_TABLE_COLUMN_CLIENT_SHORT_NAME =
  "VISITS_TABLE_COLUMN_CLIENT_SHORT_NAME";
export const VISITS_TABLE_COLUMN_CLIENT_NAME =
  "VISITS_TABLE_COLUMN_CLIENT_NAME";
export const VISITS_TABLE_COLUMN_CONTACT_PERSON =
  "VISITS_TABLE_COLUMN_CONTACT_PERSON";
export const VISITS_TABLE_COLUMN_START_DATE = "VISITS_TABLE_COLUMN_START_DATE";
export const VISITS_TABLE_COLUMN_END_DATE = "VISITS_TABLE_COLUMN_END_DATE";
export const VISITS_TABLE_COLUMN_CREATED_DATE_LABEL =
  "VISITS_TABLE_COLUMN_CREATED_DATE_LABEL";
export const VISITS_TABLE_COLUMN_LAST_UPDATED_DATE_LABEL =
  "VISITS_TABLE_COLUMN_LAST_UPDATED_DATE_LABEL";
export const VISITS_TABLE_COLUMN_CLIENT_EMAIL =
  "VISITS_TABLE_COLUMN_CLIENT_EMAIL";
export const VISITS_TABLE_COLUMN_CONTACT_PERSON_EMAIL =
  "VISITS_TABLE_COLUMN_CONTACT_PERSON_EMAIL";
export const VISITS_TABLE_COLUMN_VISITOR_EMAIL =
  "VISITS_TABLE_COLUMN_VISITOR_EMAIL";
export const VISITS_TABLE_COLUMN_CLIENT_PHONE =
  "VISITS_TABLE_COLUMN_CLIENT_PHONE";
export const VISITS_TABLE_COLUMN_CONTACT_PERSON_PHONE =
  "VISITS_TABLE_COLUMN_CONTACT_PERSON_PHONE";
export const VISITS_TABLE_COLUMN_VISITOR_PHONE =
  "VISITS_TABLE_COLUMN_VISITOR_PHONE";
export const VISITS_TABLE_COLUMN_NOTES = "VISITS_TABLE_COLUMN_NOTES";
export const VISITS_TABLE_COLUMN_TYPE_LABEL = "VISITS_TABLE_COLUMN_TYPE_LABEL";
export const VISITS_TABLE_COLUMN_STATUS = "VISITS_TABLE_COLUMN_STATUS";
export const VISITS_TABLE_COLUMN_CREATED_BY = "VISITS_TABLE_COLUMN_CREATED_BY";
export const VISITS_TABLE_COLUMN_ORDER_SUM = "VISITS_TABLE_COLUMN_ORDER_SUM";
export const VISITS_TABLE_COLUMN_CATEGORY_NAME =
  "VISITS_TABLE_COLUMN_CATEGORY_NAME";
