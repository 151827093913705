//TODO: lazy import language objects
import EN from "./en";
//import DE from "./de";
import TR from "./tr";

//language codes
export const LANGUAGE_CODE_ENGLISH = 0;
export const LANGUAGE_CODE_TURKISH = 1;

//helpers
const localeToLangCodeMappings = {
  en: LANGUAGE_CODE_ENGLISH,
  tr: LANGUAGE_CODE_TURKISH,
};

export function getMenuLangCodeByUserLocale() {
  let userLocale = navigator.language;
  let locale = Object.keys(localeToLangCodeMappings).find((key) =>
    userLocale.includes(key)
  );
  return locale ? localeToLangCodeMappings[locale] : LANGUAGE_CODE_ENGLISH;
}
export function getMenuLangObjectByUserLocale() {
  return getMenuLangObject(getMenuLangCodeByUserLocale());
}

export default function getMenuLangObject(langCode) {
  switch (langCode) {
    case LANGUAGE_CODE_ENGLISH:
      return EN;
    case LANGUAGE_CODE_TURKISH:
      return TR;
    default:
      throw new Error("Language not found by the given langCode=" + langCode);
  }
}
