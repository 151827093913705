import {
  VISITS_TABLE_COLUMN_VISITOR,
  VISITS_TABLE_COLUMN_CONTACT_PERSON,
  VISITS_TABLE_COLUMN_START_DATE,
  VISITS_TABLE_COLUMN_NOTES,
  VISITS_TABLE_COLUMN_TYPE_LABEL,
  VISITS_TABLE_COLUMN_STATUS,
  VISITS_TABLE_COLUMN_ORDER_SUM,
} from "components/Common/VisitsTable/tableColumns";

const clientReportsShowColumns = [
  VISITS_TABLE_COLUMN_VISITOR,
  VISITS_TABLE_COLUMN_CONTACT_PERSON,
  VISITS_TABLE_COLUMN_START_DATE,
  VISITS_TABLE_COLUMN_NOTES,
  VISITS_TABLE_COLUMN_TYPE_LABEL,
  VISITS_TABLE_COLUMN_STATUS,
  VISITS_TABLE_COLUMN_ORDER_SUM,
];

export default clientReportsShowColumns;
