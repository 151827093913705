import initialState from "redux/reducers/initialState";
import { LOGOUT_SUCCESS } from "redux/actions";
import { GET_CLIENTS } from "redux/actions";
import { CREATE_CLIENT } from "redux/actions";
import { UPDATE_CLIENT } from "redux/actions";
import { DELETE_CLIENT } from "redux/actions";
import { GET_DEPARTMENTS } from "redux/actions";
import { CREATE_DEPARTMENT } from "redux/actions";
import { CHANGE_CLIENT_DIALOG_STATE } from "redux/actions";

export default function clientManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.data,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.data],
      };
    case UPDATE_CLIENT:
      let clients = state.clients.map((c) =>
        c.id === action.data.id ? action.data : c
      );
      return {
        ...state,
        clients: clients,
      };
    case DELETE_CLIENT:
      let clients2 = state.clients.filter((c) => c.id !== action.data.id);
      return {
        ...state,
        clients: clients2,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.data,
      };
    case CREATE_DEPARTMENT:
      return {
        ...state,
        departments: [...state.departments, action.data],
      };
    case LOGOUT_SUCCESS:
      return {
        clients: [],
      };
    case CHANGE_CLIENT_DIALOG_STATE:
      return {
        ...state,
        clientDialog: {
          ...state.clientDialog,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
