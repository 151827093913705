import { lazy, Suspense } from "react";
import ErrorBoundary from "views/ErrorBoundary";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import Loading from "views/Loading";
import withClearCache from "./withClearCache";

const MasterPage = lazy(() => import("views/master.page"));

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Suspense fallback={<Loading>Loading...</Loading>}>
          <Router>
            <MasterPage />
          </Router>
        </Suspense>
      </ErrorBoundary>
    </Provider>
  );
}

export default withClearCache(App);
