//Login Actions
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_CONFIRM_DIALOG = "LOGIN_CONFIRM_DIALOG";
export const GET_USER_INFO = "GET_USER_INFO";
export const LOGIN_WAIT = "LOGIN_WAIT";
export const CLEAR_LOGIN_ERROR_MESSAGES = "CLEAR_LOGIN_ERROR_MESSAGES";
export const REDIRECT_PATH = "REDIRECT_PATH";
export const RESET_LEFT_MENU = "RESET_LEFT_MENU";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const PASSWORD_CHANGE_ATTEMPT_FAILED = "PASSWORD_CHANGE_ATTEMPT_FAILED";

//History Actions
export const PAGE_TRANSITION = "PAGE_TRANSITION";

//View, Theme and Localization Actions
export const CHANGE_THEME = "CHANGE_THEME";
export const CHANGE_LANGUAGE_HELP_TEXT = "CHANGE_LANGUAGE_HELP_TEXT";
export const CHANGE_LANGUAGE_MENU_TEXT = "CHANGE_LANGUAGE_MENU_TEXT";
export const CHANGE_FULL_SCREEN_MODE = "CHANGE_FULL_SCREEN_MODE";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SHOW_SNACKBAR_FORMATTED = "SHOW_SNACKBAR_FORMATTED";

//Save Actions
export const SAVE_WAIT = "SAVE_WAIT";
export const SAVE_DONE = "SAVE_DONE";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PREFERENCES = "CHANGE_USER_PREFERENCES";

//Load Actions
export const LOAD_WAIT = "LOAD_WAIT";
export const LOAD_DONE = "LOAD_DONE";

//Upload Actions
export const UPLOAD_STARTED = "UPLOAD_STARTED";
export const UPLOAD_RUNNING = "UPLOAD_RUNNING";
export const UPLOAD_FAILED = "UPLOAD_FAILED";
export const UPLOAD_DONE = "UPLOAD_DONE";
export const UPLOAD_RESET = "UPLOAD_RESET";
export const UPLOAD_CANCEL = "UPLOAD_CANCEL";
export const UPLOAD_SHOW = "UPLOAD_SHOW";
export const UPLOAD_HIDE = "UPLOAD_HIDE";
export const BUNDLE_INSTALLING = "BUNDLE_INSTALLING";
export const DEVICE_WILL_RESTART = "DEVICE_WILL_RESTART";

//Error Actions
export const REQUEST_VALIDATION_ERROR = "REQUEST_VALIDATION_ERROR";
export const CLEAR_VALIDATON_ERRORS = "CLEAR_VALIDATON_ERRORS";
export const CLEAR_VALIDATON_ERROR = "CLEAR_VALIDATON_ERROR";

//RoleManagement View Releted Actions
export const GET_APPMODULES = "GET_APP_MODULES";
export const GET_PATHS = "GET_PATHS";
export const CREATE_APPMODULE = "CREATE_APPMODULE";
export const UPDATE_APPMODULE = "UPDATE_APPMODULE";
export const DELETE_APPMODULE = "DELETE_APPMODULE";
export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const GET_ROLE_MANAGEMENTS = "GET_ROLE_MANAGEMENTS";
export const CREATE_ROLE_MANAGEMENT = "CREATE_ROLE_MANAGEMENT";
export const DELETE_ROLE_MANAGEMENT = "DELETE_ROLE_MANAGEMENT";

//UserManagement View Releated Actions
export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

//ClientManagement View Related Actions
export const GET_CLIENTS = "GET_CLIENTS";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CHANGE_CLIENT_DIALOG_STATE = "CHANGE_CLIENT_DIALOG_STATE";

//Visits View Related Actions
export const GET_VISIT_LIST = "GET_VISIT_LIST";
export const GET_MY_VISIT_LIST = "GET_MY_VISIT_LIST";
export const GET_VISIT_CATEGORIES = "GET_VISIT_CATEGORIES";
export const CREATE_VISIT_CATEGORY = "CREATE_VISIT_CATEGORY";
export const SET_MY_VISITS_MODE = "SET_MY_VISITS_MODE";
export const UPDATE_VISIT_DETAIL = "UPDATE_VISIT_DETAIL";

//Visits Filter
export const VISITS_RESET_FILTER = "VISITS_RESET_FILTER";
export const VISITS_APPLY_FILTER = "VISITS_APPLY_FILTER";
export const VISITS_APPLY_FILTER_DONE = "VISITS_APPLY_FILTER_DONE";
export const VISITS_CHANGE_FILTER = "VISITS_CHANGE_FILTER";

//Visits Quick Filter
export const VISITS_SELECTED_QUICK_FILTER = "VISITS_SELECTED_QUICK_FILTER";
export const VISITS_MY_ALL_FILTERS = "VISITS_MY_ALL_FILTERS";
export const VISITS_CREATE_MY_QUICK_FILTERS = "VISITS_CREATE_MY_QUICK_FILTERS";
export const VISITS_UPDATE_MY_QUICK_FILTERS = "VISITS_UPDATE_MY_QUICK_FILTERS";
export const VISITS_DELETE_MY_QUICK_FILTERS = "VISITS_DELETE_MY_QUICK_FILTERS";

//Generic Material Table Actions
export const MATERIAL_TABLE_REFRESH = "MATERIAL_TABLE_REFRESH";
export const MATERIAL_TABLE_REFRESH_DONE = "MATERIAL_TABLE_REFRESH_DONE";
export const MATERIAL_TABLE_RELOAD = "MATERIAL_TABLE_RELOAD";
export const MATERIAL_TABLE_RELOAD_DONE = "MATERIAL_TABLE_RELOAD_DONE";

//Worker Component Actions
export const WORKERS_ALL_INITIALIZED = "WORKERS_ALL_INITIALIZED";

//User Key Value Store Actions
export const USER_KEY_VALUE_STORE_INITIALIZE_STORE =
  "USER_KEY_VALUE_STORE_INITIALIZE_STORE";
export const USER_KEY_VALUE_STORE_SYNC_STORE =
  "USER_KEY_VALUE_STORE_SYNC_STORE";
export const USER_KEY_VALUE_STORE_UPDATE_STORE =
  "USER_KEY_VALUE_STORE_UPDATE_STORE";
