import CreateMuiTheme from "@material-ui/core/styles/createMuiTheme";
import Avant from "assets/fonts/avant.woff2";

const avant = {
  fontFamily: "Avant",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Raleway'),
    local('Raleway-Regular'),
    url(${Avant}) format('woff2')
  `,
};

//Main Theme
const Light_Theme = CreateMuiTheme({
  typography: {
    fontFamily: [
      "Avant",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  palette: {
    type: "light",
    primary: {
      light: "#4CACC1",
      main: "#008AA8",
      dark: "#00242C",
    },
    success: {
      main: "#008000",
    },
    action: {
      selected: "rgb(140,140,140)",
    },
    error: {
      main: "#d32f2f",
    },
    text: {
      primary: "rgba(0,0,0,0.87)",
      secondary: "rgba(0,0,0,0.67)",
      disabled: "rgba(0, 0, 0, 0.48)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.15)",
    background: {
      paper: "rgb(220,220,220)",
      default: "rgb(220,220,220)",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [avant],
      },
    },
    MuiFilledInput: {
      input: {
        paddingTop: 34,
      },
    },
    MuiTableCell: {
      head: {
        color: "#FFFFFF",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#FFFFFF",
      },
      active: {
        color: "#FFFFFF",
      },
      icon: {
        color: "#FFFFFF",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgb(0,0,0)",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiSnackbarContent: {
      root: {
        color: "#FFFFFF",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
      },
    },
  },
});

//Main Theme
const Dark_Theme = CreateMuiTheme({
  typography: {
    fontFamily: [
      "Avant",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  palette: {
    type: "dark",
    primary: {
      light: "#00242C",
      main: "#00242C",
      dark: "#00242C",
    },
    success: {
      main: "#bac778",
    },
    action: {
      selected: "rgb(30,30,30)",
    },
    error: {
      main: "#d33f2f",
    },
    text: {
      primary: "rgba(240,240,240,0.87)",
      secondary: "rgba(240,240,240,0.67)",
      disabled: "rgba(240, 240, 240, 0.48)",
      hint: "rgba(240, 240, 240, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.15)",
    background: {
      paper: "rgb(60,60,60)",
      default: "rgb(60,60,60)",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [avant],
      },
    },
    MuiFilledInput: {
      input: {
        paddingTop: 34,
      },
    },
    MuiTableCell: {
      head: {
        color: "#FFFFFF",
      },
    },
    MuiTableSortLabel: {
      root: {
        color: "#FFFFFF",
      },
      active: {
        color: "#FFFFFF",
      },
      icon: {
        color: "#FFFFFF",
      },
    },
    MuiMenuItem: {
      root: {
        color: "rgb(200,200,200)",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiSnackbarContent: {
      root: {
        color: "#FFFFFF",
      },
    },
  },
});

export const THEME_CODE_LIGHT = 0;
export const THEME_CODE_DARK = 1;

export default function getThemeObject(themeCode) {
  switch (themeCode) {
    case THEME_CODE_LIGHT:
      return Light_Theme;
    case THEME_CODE_DARK:
      return Dark_Theme;
    default:
      throw new Error("Theme object not found by given themeCode=" + themeCode);
  }
}
