import initialState from "redux/reducers/initialState";
import { WORKERS_ALL_INITIALIZED } from "redux/actions";
import _ from "lodash";

const initialStateCopy = _.cloneDeep(initialState.workers);

export default function workersReducer(state = initialStateCopy, action) {
  switch (action.type) {
    case WORKERS_ALL_INITIALIZED: {
      return {
        allInitialized: true,
      };
    }
    default:
      return state;
  }
}
