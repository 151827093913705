import {
  USER_KEY_VALUE_STORE_INITIALIZE_STORE,
  USER_KEY_VALUE_STORE_SYNC_STORE,
  USER_KEY_VALUE_STORE_UPDATE_STORE,
} from "redux/actions/index";
import initialState from "redux/reducers/initialState";
import _ from "lodash";
import {
  TYPES,
  KEYS,
} from "components/CustomHooks/useKeyValueStore/keysAndTypes";
import { TABLE_RESTRICTION_CONFIG } from "components/Common/VisitsTable/minMaxColumnRestrictions";

export default function reducerUserKeyValueStore(state = initialState, action) {
  switch (action.type) {
    case USER_KEY_VALUE_STORE_INITIALIZE_STORE: {
      let tmpState = _.cloneDeep(initialState.keyValueStore);
      action.data.map((item) => {
        try {
          switch (item.key) {
            case KEYS.ALL_VISITS_COLUMNS:
            case KEYS.MY_VISITS_COLUMNS:
            case KEYS.CLIENT_REPORTS_COLUMNS:
            case KEYS.USER_REPORTS_COLUMNS: {
              if (
                JSON.parse(item.value).length >=
                  TABLE_RESTRICTION_CONFIG.restrictColumnsMinCount &&
                JSON.parse(item.value).length <=
                  TABLE_RESTRICTION_CONFIG.restrictColumnsMaxCount
              ) {
                tmpState.store[item.key] = JSON.parse(item.value);
              }
              break;
            }
            default: {
              tmpState.store[item.key] = JSON.parse(item.value);
            }
          }
        } catch {
          tmpState.store[item.key] = item.value;
        }
        return undefined;
      });
      return tmpState;
    }
    case USER_KEY_VALUE_STORE_SYNC_STORE:
      return {
        ...state,
        pendingChangesList: [],
        waitingForSync: false,
      };
    case USER_KEY_VALUE_STORE_UPDATE_STORE: {
      let tmpState = _.cloneDeep(state);
      switch (action.data.type) {
        case TYPES.INTEGER_ARRAY:
        case TYPES.STRING_ARRAY:
        case TYPES.FLOAT_ARRAY:
        case TYPES.DOUBLE_ARRAY:
        case TYPES.DATE_ARRAY:
        case TYPES.DATETIME_ARRAY: {
          if (tmpState.store[action.data.key].includes(action.data.value)) {
            tmpState.store[action.data.key] = _.cloneDeep(
              tmpState.store[action.data.key].filter(
                (e) => e !== action.data.value
              )
            );
          } else {
            if (Array.isArray(tmpState.store[action.data.key])) {
              tmpState.store[action.data.key].push(action.data.value);
            }
          }
          tmpState.pendingChangesList.push({
            key: action.data.key,
            value: _.cloneDeep(tmpState.store[action.data.key]),
          });
          break;
        }
        case TYPES.INTEGER: {
          tmpState.store[action.data.key] = parseInt(action.data.value);
          tmpState.pendingChangesList.push({
            key: action.data.key,
            value: action.data.value,
          });
          break;
        }
        case TYPES.FLOAT:
        case TYPES.DOUBLE: {
          tmpState.store[action.data.key] = parseFloat(action.data.value);
          tmpState.pendingChangesList.push({
            key: action.data.key,
            value: action.data.value,
          });
          break;
        }
        case TYPES.BOOLEAN: {
          tmpState.store[action.data.key] = Boolean(action.data.value);
          tmpState.pendingChangesList.push({
            key: action.data.key,
            value: action.data.value,
          });
          break;
        }
        default: {
          //otherwise just change the value
          tmpState.store[action.data.key] = action.data.value;
          tmpState.pendingChangesList.push({
            key: action.data.key,
            value: action.data.value,
          });
        }
      }
      tmpState.waitingForSync = true;
      return tmpState;
    }
    default: {
      return state;
    }
  }
}
