import initialState from "redux/reducers/initialState";
import {
  VISITS_SELECTED_QUICK_FILTER,
  VISITS_MY_ALL_FILTERS,
  VISITS_CREATE_MY_QUICK_FILTERS,
  VISITS_UPDATE_MY_QUICK_FILTERS,
  VISITS_DELETE_MY_QUICK_FILTERS,
} from "redux/actions";
import _ from "lodash";

const initialStateCopy = _.cloneDeep(initialState.quickFilter);

export default function quickFilterReducer(state = initialStateCopy, action) {
  switch (action.type) {
    case VISITS_SELECTED_QUICK_FILTER: {
      return {
        ...state,
        selectedQuickFilter: action.data,
      };
    }
    case VISITS_MY_ALL_FILTERS: {
      return {
        ...state,
        myAllFilters: action.data,
      };
    }
    case VISITS_CREATE_MY_QUICK_FILTERS: {
      return {
        ...state,
        myAllFilters: [...state.myAllFilters, action.data],
      };
    }
    case VISITS_UPDATE_MY_QUICK_FILTERS: {
      let updateMyAllFilters = state.myAllFilters.map((u) =>
        u.id === action.data.id ? action.data : u
      );
      return {
        ...state,
        myAllFilters: updateMyAllFilters,
      };
    }
    case VISITS_DELETE_MY_QUICK_FILTERS: {
      let deletedMyAllFilters = state.myAllFilters.filter(
        (u) => u.id !== action.data.id
      );
      return {
        ...state,
        myAllFilters: deletedMyAllFilters,
      };
    }
    default:
      return state;
  }
}
