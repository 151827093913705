import { LOAD_WAIT, LOAD_DONE } from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_WAIT:
      return state + 1;
    case LOAD_DONE:
      return state - 1;
    default:
      return state;
  }
}
