import BissLogo from "assets/img/biss.svg";
import "assets/css/animate.css";
import { Animated } from "react-animated-css";
import { Typography, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: 25,
  },
}));
export default function Loading(props) {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "98vh",
        position: "relative",
      }}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={1000}
        animationOutDuration={1500}
        isVisible={true}
      >
        <img
          src={BissLogo}
          alt="loadingScreen"
          style={{ height: 120, display: "flex" }}
        />
        <center>
          <Typography className={classes.text} variant="h5">
            {props.children}
          </Typography>
        </center>
      </Animated>
    </div>
  );
}
