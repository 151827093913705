import initialState from "redux/reducers/initialState";
import {
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
} from "redux/actions";
import { LOGOUT_SUCCESS } from "redux/actions";

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.data,
      };
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.data],
      };
    case UPDATE_USER:
      let users = state.users.map((u) =>
        u.id === action.data.id ? action.data : u
      );
      return {
        ...state,
        users: users,
      };
    case DELETE_USER:
      let users2 = state.users.filter((u) => u.id !== action.data.id);
      return {
        ...state,
        users: users2,
      };
    case LOGOUT_SUCCESS:
      return {
        users: [],
      };
    default:
      return state;
  }
}
