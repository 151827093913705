export const ROUTE_DASHBOARD = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_USER_SETTINGS = "/user-settings";
export const ROUTE_ROLE_MANAGEMENT = "/role-management";
export const ROUTE_USER_MANAGEMENT = "/user-management";
export const ROUTE_CLIENT_MANAGEMENT = "/client-management";
export const ROUTE_VISITS = "/meetings";
export const ROUTE_MY_VISITS = "/my-meetings";
export const ROUTE_LOGS = "/logs";
export const ROUTE_REPORTS_PAGE = "/reports-page";
