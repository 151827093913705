import {
  CHANGE_LANGUAGE_MENU_TEXT,
  CHANGE_LANGUAGE_HELP_TEXT,
} from "redux/actions/index";
import initialState from "redux/reducers/initialState";
import getMenuLangObject from "localization/Menu";
import helpLanguage from "localization/HelpText";

export default function reducerLanguage(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE_MENU_TEXT: {
      return {
        ...state,
        menu: getMenuLangObject(action.data),
      };
    }
    case CHANGE_LANGUAGE_HELP_TEXT: {
      return {
        ...state,
        helpText: helpLanguage[action.data],
      };
    }
    default: {
      return state;
    }
  }
}
