import languageHelp from "localization/HelpText";
import { VIEW_MODE } from "enums/openMode";
import { ROUTE_DASHBOARD } from "components/MasterPage/AppBar/LeftMenu/routesNames";
import { getMenuLangObjectByUserLocale } from "localization/Menu";
import { APP_CONFIG_DEFAULT_THEME_CODE } from "appConfig";
import { getMenuLangCodeByUserLocale } from "localization/Menu";

//Key Value Store
import { showColumns } from "components/Common/VisitsTable/columnShowConfig";
import { KEYS as UserKeyValueStoreKeys } from "components/CustomHooks/useKeyValueStore/keysAndTypes";
import { SORT_BY_LAST_UPDATED } from "enums/sortByOptions";
import clientReportsShowColumns from "views/ReportsPage/Clients/Table/columnShowConfig";
import userReportsShowColumns from "views/ReportsPage/Users/Table/columnShowConfig";

const INITIAL_STATE = {
  themeCode: APP_CONFIG_DEFAULT_THEME_CODE,
  language: {
    menu: getMenuLangObjectByUserLocale(),
    helpText: languageHelp.en,
  },
  history: {
    location: {
      pathname: ROUTE_DASHBOARD,
    },
  },
  session: {
    user: null,
    waiting: true,
    logoutSuccess: false,
    error: null,
    redirectPath: ROUTE_DASHBOARD,
    resetLeftMenu: false,
    settings: {
      theme: APP_CONFIG_DEFAULT_THEME_CODE,
      language: getMenuLangCodeByUserLocale(),
      timezone: 0,
      receiveSystemEmails: true,
    },
    failedPasswordChangeAttempsCount: 0,
  },
  fullScreenMode: false,
  snackbar: {
    show: false,
  },
  save: {},
  upload: {
    show: false,
    status: 0,
    restart: false,
  },
  errors: {
    validationErrors: {},
    apiName: null,
  },
  loading: 0,
  roleManagement: {
    appModules: [],
    paths: [],
    roles: [],
    roleManagements: [],
  },
  userManagement: {
    users: [],
  },
  clientManagement: {
    clients: [],
    departments: [],
    clientDialog: {
      open: false,
      infoSectionMode: VIEW_MODE,
    },
  },
  visits: {
    visitList: [],
    visitCategories: [],
    myVisitsMode: true,
    visitDetail: null,
  },
  visitsFilter: {
    category: [],
    isTeleMeeting: "",
    startDate: null,
    endDate: null,
    fromQuickDate: null,
    toQuickDate: null,
    status: [],
    visitor: [],
    client: [],
    contactPerson: [],
    createdBy: [],
  },
  quickFilter: {
    selectedQuickFilter: null,
    myAllFilters: [],
  },
  materialTable: {
    refreshTable: false,
    reloadTable: false,
  },
  workers: {
    allInitialized: false,
  },
  keyValueStore: {
    waitingForSync: false,
    pendingChangesList: [],
    store: {
      [UserKeyValueStoreKeys.ALL_VISITS_PAGE_SIZE]: 10,
      [UserKeyValueStoreKeys.ALL_VISITS_COLUMNS]: showColumns,
      [UserKeyValueStoreKeys.ALL_VISITS_SORT_DIRECTION]: true, //sortByDescending
      [UserKeyValueStoreKeys.ALL_VISITS_SORT_BY_OPTION]: SORT_BY_LAST_UPDATED,
      [UserKeyValueStoreKeys.MY_VISITS_PAGE_SIZE]: 10,
      [UserKeyValueStoreKeys.MY_VISITS_COLUMNS]: showColumns,
      [UserKeyValueStoreKeys.MY_VISITS_SORT_DIRECTION]: true, //sortByDescending
      [UserKeyValueStoreKeys.MY_VISITS_SORT_BY_OPTION]: SORT_BY_LAST_UPDATED,
      [UserKeyValueStoreKeys.CLIENT_REPORTS_PAGE_SIZE]: 10,
      [UserKeyValueStoreKeys.CLIENT_REPORTS_COLUMNS]: clientReportsShowColumns,
      [UserKeyValueStoreKeys.USER_REPORTS_PAGE_SIZE]: 10,
      [UserKeyValueStoreKeys.USER_REPORTS_COLUMNS]: userReportsShowColumns,
    },
  },
};

export default INITIAL_STATE;
