import {
  MATERIAL_TABLE_REFRESH,
  MATERIAL_TABLE_REFRESH_DONE,
  MATERIAL_TABLE_RELOAD,
  MATERIAL_TABLE_RELOAD_DONE,
  CHANGE_LANGUAGE_MENU_TEXT,
} from "redux/actions/index";
import initialState from "redux/reducers/initialState";

export default function materialTableReducer(state = initialState, action) {
  switch (action.type) {
    case MATERIAL_TABLE_REFRESH: {
      return {
        ...state,
        refreshTable: true,
      };
    }
    case MATERIAL_TABLE_REFRESH_DONE: {
      return {
        ...state,
        refreshTable: false,
      };
    }
    case MATERIAL_TABLE_RELOAD: {
      return {
        ...state,
        reloadTable: true,
      };
    }
    case MATERIAL_TABLE_RELOAD_DONE: {
      return {
        ...state,
        reloadTable: false,
      };
    }
    //refresh table to rerender localized text
    case CHANGE_LANGUAGE_MENU_TEXT: {
      return {
        ...state,
        refreshTable: true,
      };
    }
    default: {
      return state;
    }
  }
}
