import {
  REQUEST_VALIDATION_ERROR,
  CLEAR_VALIDATON_ERRORS,
} from "redux/actions";
import initialState from "redux/reducers/initialState";
import { CLEAR_VALIDATON_ERROR } from "redux/actions";

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: action.data.errorCodes,
        apiName: action.data.apiName,
      };
    case CLEAR_VALIDATON_ERRORS:
      return {
        validationErrors: {},
        apiName: null,
      };
    case CLEAR_VALIDATON_ERROR:
      state.validationErrors[action.data] = null;
      return {
        ...state,
        validationErrors: { ...state.validationErrors },
      };
    default:
      return state;
  }
}
