import { CHANGE_FULL_SCREEN_MODE } from "redux/actions";
import initialState from "redux/reducers/initialState";
export default function reducerFullScreen(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FULL_SCREEN_MODE: {
      return !state;
    }
    default: {
      return state;
    }
  }
}
