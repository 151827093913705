import {
  GET_APPMODULES,
  GET_PATHS,
  CREATE_APPMODULE,
  UPDATE_APPMODULE,
  DELETE_APPMODULE,
  GET_ROLES,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_ROLE_MANAGEMENTS,
  CREATE_ROLE_MANAGEMENT,
  DELETE_ROLE_MANAGEMENT,
} from "redux/actions";
import initialState from "redux/reducers/initialState";
import { LOGOUT_SUCCESS } from "redux/actions";

export default function roleManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPMODULES:
      return {
        ...state,
        appModules: action.data,
      };
    case GET_PATHS:
      return {
        ...state,
        paths: action.data,
      };
    case CREATE_APPMODULE:
      return {
        ...state,
        appModules: [...state.appModules, action.data],
      };
    case UPDATE_APPMODULE:
      let appModules = state.appModules.map((m) => {
        if (m.id === action.data.id) return action.data;
        return m;
      });
      return {
        ...state,
        appModules: appModules,
      };
    case DELETE_APPMODULE:
      let appModules2 = state.appModules.filter((m) => m.id !== action.data.id);
      return {
        ...state,
        appModules: appModules2,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.data,
      };
    case CREATE_ROLE:
      return {
        ...state,
        roles: [...state.roles, action.data],
      };
    case UPDATE_ROLE:
      let roles = state.roles.map((role) => {
        if (role.id === action.data.id) return action.data;
        return role;
      });
      return {
        ...state,
        roles: roles,
      };
    case DELETE_ROLE:
      let roles2 = state.roles.filter((role) => role.id !== action.data.id);
      return {
        ...state,
        roles: roles2,
      };

    case GET_ROLE_MANAGEMENTS:
      return {
        ...state,
        roleManagements: action.data,
      };
    case CREATE_ROLE_MANAGEMENT:
      return {
        ...state,
        roleManagements: [...state.roleManagements, action.data],
      };
    case DELETE_ROLE_MANAGEMENT:
      let roleManagements = state.roleManagements.filter(
        (rm) => rm.id !== action.data.id
      );
      return {
        ...state,
        roleManagements: roleManagements,
      };
    case LOGOUT_SUCCESS:
      return {
        roles: [],
        roleManagements: [],
        appModules: [],
        paths: [],
      };
    default:
      return state;
  }
}
