import {
  UPLOAD_STARTED,
  UPLOAD_RUNNING,
  UPLOAD_FAILED,
  UPLOAD_DONE,
  UPLOAD_RESET,
  UPLOAD_CANCEL,
  UPLOAD_SHOW,
  UPLOAD_HIDE,
  BUNDLE_INSTALLING,
  DEVICE_WILL_RESTART,
} from "redux/actions";
import initialState from "redux/reducers/initialState";
/*
-1: Upload canceled
0: Upload Not Started
1: Upload Running
2: Upload Failed
3: Upload Done
*/

export default function reducerUpload(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_STARTED:
      return {
        ...state,
        show: true,
        componentPath: action.data.componentPath,
        fileName: action.data.fileName,
        totalSize: action.data.totalSize,
        fileType: action.data.fileType,
        cancel: false,
        status: 1,
        cancelToken: action.data.cancelToken,
        restart: false,
        installAfterUpload: action.data.installAfterUpload,
      };
    case UPLOAD_RUNNING:
      return {
        ...state,
        totalUploaded: action.data.totalUploaded,
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        status: 2,
      };

    case UPLOAD_DONE:
      return {
        ...state,
        status: 3,
        uploaded: action.data,
      };
    case UPLOAD_RESET: {
      return {
        show: false,
        status: 0,
        restart: false,
      };
    }
    case UPLOAD_CANCEL: {
      return {
        ...state,
        show: false,
        status: -1,
        cancel: true,
      };
    }
    case UPLOAD_SHOW: {
      return {
        ...state,
        show: true,
      };
    }

    case UPLOAD_HIDE: {
      return {
        ...state,
        show: false,
      };
    }

    case BUNDLE_INSTALLING: {
      return {
        ...state,
        show: false,
        status: 5,
      };
    }

    case DEVICE_WILL_RESTART: {
      return {
        ...state,
        restart: true,
      };
    }

    default: {
      return state;
    }
  }
}
