import {
  GET_USER_INFO,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_LOGIN_ERROR_MESSAGES,
  REQUEST_VALIDATION_ERROR,
  REDIRECT_PATH,
  RESET_LEFT_MENU,
  SET_USER_SETTINGS,
  PASSWORD_CHANGE_ATTEMPT_FAILED,
} from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        user: action.data || null,
        waiting: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logoutSuccess: true,
        user: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.data,
        logoutSuccess: false,
        autoLogout: false,
        waiting: false,
        error: null,
      };
    case REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.data,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        error: action.data,
        loginFail: true,
      };
    case CLEAR_LOGIN_ERROR_MESSAGES: {
      return {
        ...state,
        error: null,
      };
    }
    case REQUEST_VALIDATION_ERROR: {
      return {
        ...state,
      };
    }
    case RESET_LEFT_MENU: {
      return {
        ...state,
        resetLeftMenu: !state.resetLeftMenu,
      };
    }
    case SET_USER_SETTINGS: {
      return {
        ...state,
        settings: action.data,
      };
    }
    case PASSWORD_CHANGE_ATTEMPT_FAILED: {
      return {
        ...state,
        failedPasswordChangeAttempsCount:
          state.failedPasswordChangeAttempsCount + 1,
      };
    }
    default:
      return state;
  }
}
