import { PAGE_TRANSITION } from "redux/actions/index";
import initialState from "redux/reducers/initialState";

export default function reducerPageTransition(state = initialState, action) {
  switch (action.type) {
    case PAGE_TRANSITION: {
      return action.data;
    }
    default: {
      return state;
    }
  }
}
