import { SAVE_WAIT, SAVE_DONE } from "redux/actions";
import initialState from "redux/reducers/initialState";

export default function saveReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_WAIT:
      state[action.data] = true;
      return {
        ...state,
      };
    case SAVE_DONE:
      state[action.data] = false;
      return {
        ...state,
      };
    default:
      return state;
  }
}
